import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import WarehouseLocationEntity from "../../../warehouse_location/entity";
import Configurator from "./configurator";
import UtilsEntity from "../../../../../utils/entity";
export default class WarehouseWarehouseLocationsOverview{
    private parent: any;
    private tableElem: any;
    private datatable: any;
    private entity = "erp/warehouse_locations";
    private toastr: any;
    private editModal: bootstrap.Modal;
    private newModal: bootstrap.Modal;
    private detailModal: bootstrap.Modal;
    private configurator: any;

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.tableElem = jQuery('.erp_warehouse_location')
        this.editModal = new bootstrap.Modal((document.querySelector("#editWarehouseLocation") as HTMLElement));
        this.newModal = new bootstrap.Modal((document.querySelector("#newWarehouseLocation") as HTMLElement));
        this.detailModal = new bootstrap.Modal((document.querySelector("#erpWarehouseLocationModal") as HTMLElement));
        this.configurator = new Configurator()
        this.createTable();
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...WarehouseLocationEntity.getEntityData(elem), warehouse_id: this.parent.id}
    }

    async getSubentities() {
        const r = await UtilsEntity.getAll("product_attributes");
        if (r.status === 200) {
            return r.data.concat([{name: "order_number"}, {name: "external_order_number"}, {name: "tracking_company"}, {name: "tracking_number"}])
        } else {
            return {}
        }
    }
    bindListeners() {
        (document.querySelector("#erp_warehouse_location_bulk_add") as HTMLButtonElement).addEventListener("click", (e) => {
            e.preventDefault();
            this.configurator.onBulkAddWarehouseLocations();
        });


        (document.querySelector("#erp_warehouse_location_bulk_save") as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            await Utils.showLoader();
            await Utils.entity.upsert({
                warehouseId: this.parent.id,
                names: this.configurator.bulkData.calculated
            }, this.entity.replace("erp/", ""), this.parent.id, this.parent.entity)
            this.newModal.hide();
            await this.datatable.ajax.reload();
            await Utils.hideLoader();
            this.toastr.success(`${Utils.translate('erp.warehouse_location.names')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
        });

        document.addEventListener('keyup', (e) => {
            const target = e.target as HTMLElement
            if (target && target.classList.contains("bulk_add")) {
                this.configurator.onBulkChangeInput(e);
            }
        });
        document.addEventListener('click', (e) => {
            const target = e.target as HTMLElement
            if (target && target.classList.contains('bulk_remove')) {
                this.configurator.onRemoveBulkElement(e);
            }
        });
        document.addEventListener('change', (e) => {
            const target = e.target as HTMLElement
            if (target && target.classList.contains('bulk_selector')) {
                this.configurator.onBulkChangeSelector(e);
            }
        });

        jQuery('#warehouse_locations').delegate(".warehouse_locations_open_new_dialog", "click", async (e: any) => {
            console.log("this.configurator", this.configurator)
            this.configurator.onOpenConfigratorDialog();
        });

        this.tableElem.delegate(".delete-record", "click", async (e: any) => {const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary me-3',
                cancelButton: 'btn btn-label-secondary'
            },
            buttonsStyling: false
        })
            if(confirm.value === true) {
                e.preventDefault();
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity.replace("erp/", ""), this.parent.id, this.parent.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('erp.warehouse_location.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('erp.warehouse_location.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });

        this.tableElem.delegate(".download-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.erp.downloadLabel(id, "warehouseLocation")
            if (requestData.status === 200) {
                const pdf = requestData.data.pdf
                const arrayBuffer = Utils.base64ToArrayBuffer(pdf);
                Utils.createAndDownloadBlobFile(arrayBuffer, 'labels');
            }
        });
        this.tableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity.replace("erp/", ""), this.parent.id, this.parent.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editWarehouseLocationForm") as HTMLFormElement);
                form.reset()
                Object.keys(data).forEach((key) => {
                    const elem = form.querySelector(`#warehouse_location_${key}`) as HTMLInputElement | null
                    if (elem && data[key]) {
                        elem.value = data[key]
                    }
                })
                this.editModal.show();
            }
        });
        this.tableElem.delegate(".info-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            await Utils.showLoader();
            const r = await UtilsEntity.request(`/api/v1/erp/warehouse_location_product_mappings/by_warehouse_location_id`, 'POST', {
                warehouse_location_id: id,
            })
            const title = e.currentTarget?.getAttribute("data-title")
            let html = '<table class="table table-striped"><thead><tr>';
            if (r && r.status === 200) {
                const subEntries = await this.getSubentities();
                const data = r.data;
                html += `<th>Product</th>`
                html += `<th>Serial</th>`
                subEntries.forEach((key: any) => {
                    html += `<th>${key.name}</th>`
                })
                html += `</tr></thead><tbody>`;
                data.forEach((d: any, i: number) => {
                    html += `<tr>`
                    html += `<td>${d.product[0].name}</td>`;
                    html += `<td>${d.serials && d.serials[0] !== null ? d.serials[0] : ''}</td>`

                    subEntries.forEach((key: any) => {
                        html += `<td>${d.payload && d.payload[key.name] ? d.payload[key.name] : ''}</td>`
                    })
                    html += `</tr>`
                })
                html += `</tbody></table>`
            }
            const modalTable = document.querySelector("#erpWarehouseLocationModalTable");
            const modalTitle = document.querySelector("#erpWarehouseLocationModalTitle");
            if (modalTable) {
                modalTable.innerHTML = html;
            }
            if (modalTitle) {
                modalTitle.innerHTML = title;
            }

            jQuery("#erpWarehouseLocationModalTable table").DataTable({
                dom:
                    '<"row me-2 align-items-center"' +
                    '<"col-md-2"<"me-3 m-3"l>>' +
                    '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                    '>t' +
                    '<"row mx-2 align-items-center justify-content-between"' +
                    '<"col-6"i>' +
                    '<"col-6 mt-3"p>' +
                    '>',
                language: {
                    sLengthMenu: '_MENU_',
                    search: '',
                    searchPlaceholder: `${Utils.translate('generic.search')}...`,
                    "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                    "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                    "paginate": {
                        "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                        "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                        "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                        "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                    },
                    "info":           `${Utils.translate('generic.datatable.info.info')}`,
                    "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                    "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
                },
                buttons: [
                ]
            });

            this.detailModal.show();
            await Utils.hideLoader();
        });

        (document.querySelector("#editWarehouseLocationForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editWarehouseLocationForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity.replace("erp/", ""), this.parent.id, this.parent.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('erp.warehouse_location.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        })
    }

    createTable() {
        this.datatable = this.tableElem.DataTable({
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/erp/actions/warehouse_locations?warehouse_id=${this.parent.id}`,
            columns: [
                {data: 'name'},
                {data: 'id'},
                {data: 'id'},
                {data: 'id'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        //@ts-ignore
                        return full.stock;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${full.width}x${full.height}x${full.depth}`;
                    },
                },
                {
                    targets: 3,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="text-body download-record">
                                    <i class="ti ti-file-barcode ti-sm"></i>
                                  </a>
                                  <a href="#" data-id="${data}" data-title="${full.name}" class="text-body info-record">
                                    <i class="ti ti-info-circle ti-sm"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm"></i>
                                  </a>
                                  <a href="#" data-id="${data}" data-entity="erp/warehouse_locations" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2 warehouse_locations_open_new_dialog',
                    attr: {
                        'data-bs-toggle': 'modal',
                        'data-bs-target': '#newWarehouseLocation'
                    }
                }
            ]
        });
    }
}